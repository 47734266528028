<template>
  <section class="row items-start">
    <div class="col-12">
      <mi-form
        v-click-outside="() => toggleSearchBox(false)"
        :class="{ 'elements-search-field--expanded': isSearchBoxExpanded }"
        class="relative-position"
        no-error-focus
        @validation-error="toggleSearchBox(false)"
        @submit="onSubmit"
      >
        <mi-text-field
          v-model="searchTerm"
          :rules="searchFieldRules"
          class="elements-search-field"
          debounce="300"
          placeholder="Insert elements"
          hint="To search by more than one parameter use ';' delimiter"
          clearable
          outlined
          @focus="() => toggleSearchBox(true)"
        >
          <template #prepend>
            <mi-icon class="q-ml-xs" name="search" size="18px"></mi-icon>
          </template>
        </mi-text-field>
        <div v-show="isSearchBoxExpanded" class="search-options absolute-top-left full-width bg-white q-pb-sm">
          <div class="flex justify-start q-pb-xs q-px-md">
            <search-elements-recent-items
              v-show="recentSearchItems.length"
              :on-handle-recent-click="onHandleRecentClick"
            >
            </search-elements-recent-items>
          </div>
          <div class="flex justify-end q-pt-sm q-pb-xs q-px-md">
            <mi-btn
              class="full-width"
              color="accent"
              type="submit"
              data-cy="btn-submit"
              :disabled="!searchTerm"
            >
              Refine search
            </mi-btn>
          </div>
        </div>
      </mi-form>
    </div>
  </section>
</template>

<script>
  import { ref, inject } from 'vue'
  import { minLength } from '@/utils/validators'
  import SearchElementsRecentItems
    from '@/components/schedules/search/search-elements-section/SearchElementsRecentItems.vue'
  import { USE_RECENT_SEARCH_PROVIDER } from '@/constants'

  const searchFieldRules = [minLength(3)]

  export default {
    name: 'SearchBox',
    components: {
      SearchElementsRecentItems
    },
    props: {
      onHandleSearch: {
        type: Function,
        required: true
      }
    },
    setup(props) {
      const isSearchBoxExpanded = ref(false)
      const searchTerm = ref('')

      const { recentSearchItems } = inject(USE_RECENT_SEARCH_PROVIDER)

      const toggleSearchBox = (isExpanded = false) => {
        isSearchBoxExpanded.value = isExpanded
      }

      const onSubmit = () => {
        isSearchBoxExpanded.value = false
        props.onHandleSearch({ searchTerm: searchTerm.value })
      }

      const onHandleRecentClick = recentSearchItem => {
        searchTerm.value = recentSearchItem
        props.onHandleSearch({ searchTerm: recentSearchItem })
        toggleSearchBox(false)
      }

      return {
        isSearchBoxExpanded,
        searchTerm,
        searchFieldRules,
        toggleSearchBox,
        onSubmit,
        onHandleRecentClick,
        recentSearchItems
      }
    }
  }
</script>

<style lang="scss" scoped>
  .elements-search-field {
    ::v-deep(.q-field__control),
    ::v-deep(.q-field__after) {
      background-color: $mi-white;
    }
  }

  .elements-search-field--expanded ::v-deep(.q-field) {
    &:not(.q-field--error) {
      .q-field__control:before,
      .q-field__control:after,
      .q-field__after {
        border-color: $mi-anthracite-800;
      }
    }

    &.q-field--error + .search-options {
      border-color: $mi-red-700;
    }

    .q-field__control:before,
    .q-field__control:after,
    .q-field__after {
      border-bottom-color: transparent;
    }
  }

  .search-options {
    top: 38px;
    border-width: 0 2px 2px;
    border-style: solid;
    border-color: $mi-anthracite-900;
    z-index: 11;
  }
</style>
