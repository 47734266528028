<template>
  <div class="selected-elements-container">
    <div class="selected-elements-container__title">
      <h5 class="q-mt-none q-mb-none">
        Selected Elements
      </h5>
    </div>
    <div v-if="planningPeriod" class="q-ma-md">
      <div class="selected-elements-container__planning-period q-mb-md">
        <span class="text-subtitle2 text-weight-bold text-family-condensed">
          Planning Period
        </span>
        <mi-text-field
          :model-value="removePrefixFromPlanningPeriod(planningPeriod)"
          :disable="true"
          prefix="PP"
          outlined
        ></mi-text-field>
      </div>
      <div>
        <span class="text-subtitle2 text-weight-bold text-family-condensed">
          Table name (mandatory)
        </span>
        <mi-text-field
          v-model="collectionName"
          :rules="inputValidation"
          placeholder="Give this table a name"
          outlined
        ></mi-text-field>
      </div>
      <div class="selected-elements-container__structure-elements">
        <span class="text-subtitle2 text-weight-bold text-family-condensed">
          Structure Elements
        </span>
        <selected-elements-collection
          class="selected-elements-container__world_margin-top"
          color="yellow"
          :selected-elements="selectedElements.options"
          :selected-elements-ids="selectedElementsIds"
          :on-handle-parent-element-click="onHandleParentElementClick"
          :on-handle-child-element-click="onHandleChildElementClick"
          :on-clear-collection="onClearCollection"
        >
          <template #header>
            <span class="selected-elements-container__world-title">
              Yellow collection ({{ selectedElements.options.length }})
            </span>
          </template>
        </selected-elements-collection>
        <selected-elements-collection
          color="green"
          :selected-elements="selectedElements.components"
          :selected-elements-ids="selectedElementsIds"
          :on-handle-parent-element-click="onHandleParentElementClick"
          :on-handle-child-element-click="onHandleChildElementClick"
          :on-clear-collection="onClearCollection"
        >
          <template #header>
            <span class="selected-elements-container__world-title">
              Green collection ({{ selectedElements.components.length }})
            </span>
          </template>
        </selected-elements-collection>
      </div>
      <div v-if="selectedElements.components.length" class="set-solutions-elements">
        <div class="flex justify-between items-center">
          <span class="text-subtitle2 text-weight-bold"> Set solution elements analysis </span>
          <mi-switch
            v-model="selectedForLE"
            color="primary"
          >
          </mi-switch>
        </div>
        <div v-if="selectedForLE" class="flex justify-between q-mt-sm">
          <span class="solution-elements-caption text-caption text-weight-regular">
            {{ greenElementsTotalStr }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, inject, computed } from 'vue'
  import { maxLength, minLength } from '@/utils/validators'

  import { removePrefixFromPlanningPeriod } from '@/utils/planningPeriod'
  import { COLLECTION_NAME_PROVIDER, USE_ELEMENTS_PROVIDER } from '@/constants'
  import SelectedElementsCollection from './SelectedElementsCollection.vue'

  export default {
    name: 'SelectedElementsContainer',
    components: { SelectedElementsCollection },
    props: {
      planningPeriod: {
        type: String,
        required: false,
        default: ''
      }
    },
    setup() {
      const inputValidation = ref([
        tableName => !!tableName || 'Mandatory field',
        minLength(3, 'Min length 3 characters'),
        maxLength(80, 'Max length of 80 characters reached')])

      const collectionName = inject(COLLECTION_NAME_PROVIDER)

      const {
        selectedElements,
        selectedElementsIds,
        selectedForLE,
        onHandleChildElementClick,
        onHandleParentElementClick,
        onClearCollection
      } = inject(USE_ELEMENTS_PROVIDER)

      const greenElementsTotalStr = computed(() => {
        const total = selectedElements.components.length || 0
        return `${ total }/${ total } components`
      })

      return {
        inputValidation,
        collectionName,
        removePrefixFromPlanningPeriod,
        selectedElements,
        selectedElementsIds,
        onHandleChildElementClick,
        onHandleParentElementClick,
        onClearCollection,
        selectedForLE,
        greenElementsTotalStr
      }
    }
  }
</script>

<style lang="scss" scoped>
  .selected-elements-container {
    height: 100%;
    color: $mi-anthracite-800;

    .solution-elements-caption {
      color: $mi-anthracite-500;
    }

    .q-field {
      padding-bottom: 16px;
    }

    &__world_margin-top {
      margin-top: 4px;
    }

    &__world-title {
      color: $mi-silver-700;
    }

    &__title {
      height: 58px;
      padding: 16px;
      text-transform: uppercase;
      border-bottom: 1px solid $mi-anthracite-50;
      line-height: 26px;
    }

    &__planning-period {
      .q-field {
        padding-bottom: 0;
        width: 160px;
      }
    }
  }
</style>
