<template>
  <div v-if="productModelName" class="search-elements-container">
    <div class="search-elements-container__intro">
      <div class="search-elements-container__header-wrapper">
        <h1 class="search-elements-container__header text-uppercase">Search for Elements</h1>
      </div>
      <div>
        <span class="text-subtitle text-weight-bold text-family-condensed">
          Product Model
        </span>
        <mi-select
          :model-value="productModelName"
          :display-value="productModelName"
          :options="[]"
          class="q-mb-sm select-pm-dropdown"
          option-label="businessName"
          options-selected-class="text-accent"
          outlined
          hide-bottom-space
          disable
        ></mi-select>
      </div>
      <search-elements-form
        :on-handle-search="onHandleSearch"
      ></search-elements-form>
    </div>
    <div class="creation-components-wrapper">
      <!-- Result types -->

      <mi-list-item
        v-for="(value) in scheduleCreationComponents"
        :key="value.key"
        :to="{ query: { ...$route.query, resultType: value.key } }"
        class="collection-item text-weight-bold q-pl-lg"
        :class="{
          'collection-item--active': $route.query?.resultType === value.key,
          [`collection-item--${ value.color }`] : true
        }"
        @click="onSelectType(value.key)"
      >
        <div class="collection-item__left-border absolute-bottom-left"></div>

        <mi-list-item-section class="q-pl-xs" avatar>
          <mi-icon size="18px" :name="value.icon"></mi-icon>
        </mi-list-item-section>

        <!-- Label -->
        <mi-list-item-section>
          {{ value.label }}
        </mi-list-item-section>

        <!-- Items amount -->
        <mi-list-item-section class="collection-item__items-amount" side>
          {{ totalElements[value.key] || 0 }}
        </mi-list-item-section>

        <mi-list-item-section class="q-pl-xs" avatar>
          <img src="@/assets/images/schedule/mini-arrow-right.svg" alt="Arrow right" />
        </mi-list-item-section>
      </mi-list-item>
    </div>
  </div>
</template>

<script>
  import { SCHEDULES_RESULT_TYPES, USE_ELEMENTS_PROVIDER } from '@/constants'
  import SearchElementsForm from '@/components/schedules/search/search-elements-section/SearchElementsForm.vue'
  import { inject } from 'vue'

  export default {
    name: 'SearchElementsContainer',
    components: {
      SearchElementsForm
    },
    props: {
      productModelName: {
        type: String,
        required: false,
        default: ''
      },
      onHandleSearch: {
        type: Function,
        required: false,
        default: () => {}
      },
      onSelectType: {
        type: Function,
        required: false,
        default: () => {}
      }
    },
    setup() {
      const scheduleCreationComponents = SCHEDULES_RESULT_TYPES
      const { totalElements } = inject(USE_ELEMENTS_PROVIDER)

      return { scheduleCreationComponents, totalElements }
    }
  }
</script>

<style lang="scss" scoped>
  $collection-item-colors: ("YELLOW": $mi-yellow-400, "GREEN": $mi-green-500);

  .search-elements-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
    gap: 24px;

    .q-item {
      cursor: pointer;
    }

    .q-field {
      margin-top: 4px;
    }

    &__header {
      color: $mi-anthracite-800;
      font-size: 20px;
      line-height: 26px;
    }

    &__intro {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .text-subtitle {
    font-size: 14px;
    line-height: 14px;
    color: $mi-anthracite-800;
  }

  .collection-item {
    min-height: 60px;
    color: $mi-silver-700;
    transition: none;
    background-color: $mi-white;
    border: 1px solid $mi-silver-200;

    ::v-deep(.q-item__section--avatar) {
      min-width: auto;
      padding-right: 10px;
    }

    &:last-child {
      border-bottom: 1px solid $mi-silver-200;
    }

    &__left-border {
      width: 8px;
      top: -1px;
      z-index: 1;
    }

    &:last-child .collection-item__left-border {
      bottom: -1px;
    }

    &__items-amount {
      color: $mi-silver-700;
      padding-left: .2rem;
      font-size: 14px;
    }

    @each $color-name, $color-value in $collection-item-colors {
      &--#{$color-name} &__left-border {
        background-color: $color-value;
      }

      &--active.collection-item--#{$color-name} {
        background-color: $color-value;
        border-color: $color-value;
      }
    }
  }

  .creation-components-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
</style>
