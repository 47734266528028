<template>
  <mi-expansion-item
    :class="{
      'search-results-group--removable': removable,
      [`search-results-group--${ color }`]: true
    }"
    :default-opened="!removable"
    class="search-results-group"
    header-class="items-center"
    dense
  >
    <!-- Header -->
    <template #header>
      <mi-list-item-section class="search-results-group__action" side>
        <!-- Remove icon -->
        <mi-btn
          v-if="removable"
          :ripple="false"
          class="search-results-group__remove-btn"
          icon="trash"
          dense
          fab
          flat
          icon-type
          @click.stop="handleItemClick(elementTypes, { item, isChosen: true })"
        ></mi-btn>

        <!-- Checkbox -->
        <mi-checkbox
          v-else
          data-cy="search-results-group-item"
          :model-value="isItemSelected"
          @click.stop="handleItemClick(elementTypes, { item, isChosen: isItemSelected })"
        ></mi-checkbox>
      </mi-list-item-section>

      <!-- Title -->
      <search-results-item-title
        :code-id="item.id2"
        :name="item.name"
        :persistent="persistentTitle"
      ></search-results-item-title>
      <span v-if="activateItemCheckBoxOnlyWhenAllSelected" class="text-body3 text-weight-bold">
        {{ selectedElementsCounter }}
      </span>
    </template>

    <!-- Content -->
    <mi-list separator>
      <mi-list-item
        v-for="(subItem, index) in item[elementTypes.subItem]"
        :key="index"
        :tag="removable ? 'div' : 'label'"
        class="search-results-group__item items-center q-pl-xl"
        @click="handleSubItemClick(elementTypes, { subItem, isChosen: isSubItemSelected(subItem) })"
      >
        <mi-list-item-section class="search-results-group__action" side>
          <!-- Remove icon -->
          <mi-btn
            v-if="removable"
            :ripple="false"
            class="search-results-group__remove-btn"
            icon="trash"
            dense
            fab
            flat
            icon-type
            @click.stop="handleSubItemClick(elementTypes, { subItem, isChosen: true })"
          ></mi-btn>

          <!-- Checkbox -->
          <mi-checkbox v-else :model-value="isSubItemSelected(subItem)" class="no-pointer-events"></mi-checkbox>
        </mi-list-item-section>

        <!-- Title -->
        <search-results-item-title
          :code-id="subItem.id2"
          :name="subItem.name"
          :persistent="persistentTitle"
        ></search-results-item-title>
      </mi-list-item>
    </mi-list>
  </mi-expansion-item>
</template>

<script>
  import { ELEMENT_COLORS, SEARCH_RESULT_TYPES } from '@/constants'
  import SearchResultsItemTitle from './SearchResultsItemTitle.vue'

  export default {
    name: 'SearchResultsGroup',
    components: { SearchResultsItemTitle },
    props: {
      color: {
        type: String,
        required: false,
        default: ELEMENT_COLORS.YELLOW,
        validator: value => Object.values(ELEMENT_COLORS).includes(value)
      },
      item: {
        type: Object,
        required: true
      },
      persistentTitle: {
        type: Boolean,
        required: false,
        default: false
      },
      removable: {
        type: Boolean,
        required: false,
        default: false
      },
      activateItemCheckBoxOnlyWhenAllSelected: {
        type: Boolean,
        required: false,
        default: false
      },
      isElementFullySelected: {
        type: Function,
        required: false,
        default: () => {}
      },
      selectedElementsLength: {
        type: Function,
        required: false,
        default: () => {}
      },
      selectedElementsIds: {
        type: Object,
        required: true
      },
      handleItemClick: {
        type: Function,
        required: true
      },
      handleSubItemClick: {
        type: Function,
        required: true
      }
    },
    computed: {
      elementTypes() {
        const { OPTIONS, CHOICES, COMPONENTS, COMPONENT_VARIANTS } = SEARCH_RESULT_TYPES
        let item = ''
        let subItem = ''

        if (this.color === ELEMENT_COLORS.YELLOW) {
          item = OPTIONS
          subItem = CHOICES
        }
        else if (this.color === ELEMENT_COLORS.GREEN) {
          item = COMPONENTS
          subItem = COMPONENT_VARIANTS
        }

        return { item, subItem }
      },

      currentSelectedElementsIds() {
        return this.selectedElementsIds[this.elementTypes.item]
      },

      isItemSelected() {
        if (this.activateItemCheckBoxOnlyWhenAllSelected) {
          return this.isElementFullySelected(
            this.item, this.elementTypes, this.currentSelectedElementsIds
          )
        }

        return [...this.currentSelectedElementsIds.keys()].includes(this.item.id)
      },

      selectedElementsCounter() {
        return this.selectedElementsLength(
          this.item, this.elementTypes, this.currentSelectedElementsIds
        )
      }

    },
    methods: {
      isSubItemSelected(subItem = {}) {
        return this.removable
          ? false
          : !!this.currentSelectedElementsIds.get(this.item.id)?.includes(subItem.id)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .search-results-group {
    font-size: .875rem;

    ::v-deep(.mi-expansion-item__header) {
      border-bottom: 1px solid transparent;

      .q-expansion-item__toggle-icon {
        font-size: .5rem;
      }
    }

    &.q-expansion-item--expanded ::v-deep(.mi-expansion-item__header) {
      border-bottom-color: $mi-silver-200;
    }

    .search-results-group__action:not(.mi-expansion-item__expand-icon) {
      padding-right: .5rem;
    }

    .q-expansion-item__content .search-results-group__action {
      margin-left: -.25rem;
    }

    &__remove-btn.mi-btn {
      font-size: 1rem;
      margin-bottom: -1px;
    }

    &__item:last-child {
      box-shadow: 0 1px 0 $mi-silver-200;
    }
  }

  .search-results-group--yellow ::v-deep(.mi-expansion-item__header) {
    background-color: lighten($mi-yellow-400, 35%);
  }

  .search-results-group--yellow ::v-deep(.mi-list-item) {
    background-color: lighten($mi-yellow-400, 47%);
  }

  .search-results-group--green ::v-deep(.mi-expansion-item__header) {
    background-color: lighten($mi-green-200, 9%);
  }

  .search-results-group--green ::v-deep(.mi-list-item) {
    background-color: lighten($mi-green-500, 59%);
  }

  .search-results-group--removable .mi-expansion-item__header .search-results-group__action {
    margin-left: -.5rem;
  }

  .search-results-group--removable .q-expansion-item__content .search-results-group__action {
    margin-left: -.75rem;
  }

  .search-results-group--removable .search-results-group__action:not(.mi-expansion-item__expand-icon) {
    padding-right: .25rem;
  }
</style>
