<template>
  <div class="q-mt-md full-width">
    <!-- Title -->
    <div class="text-subtitle2 text-weight-bold "> Recent search </div>

    <!-- Recent search items list -->
    <mi-list class="q-mt-xs">
      <mi-list-item
        v-for="(recentSearchItem, index) in recentSearchItems"
        :key="index"
        class="recent-search-item items-center"
        clickable
        dense
        @click="onHandleRecentClick(recentSearchItem)"
      >
        <!-- Icon -->
        <mi-list-item-section avatar>
          <mi-icon name="search" size="18px"></mi-icon>
        </mi-list-item-section>

        <!-- Content -->
        <mi-list-item-section class="block ellipsis full-width"> {{ recentSearchItem }} </mi-list-item-section>

        <!-- Remove btn -->
        <mi-list-item-section side>
          <mi-btn
            class="q-px-sm q-pb-xs"
            :ripple="false"
            dense
            flat
            @click.stop="removeRecentSearchItem(recentSearchItem)"
          >
            <mi-icon name="close"></mi-icon>
          </mi-btn>
        </mi-list-item-section>
      </mi-list-item>
    </mi-list>
  </div>
</template>

<script>
  import { inject } from 'vue'

  import { USE_RECENT_SEARCH_PROVIDER } from '@/constants'

  export default {
    name: 'SearchElementsRecentItems',
    props: {
      onHandleRecentClick: {
        type: Function,
        required: true
      }
    },
    setup() {
      const { recentSearchItems, removeRecentSearchItem } = inject(USE_RECENT_SEARCH_PROVIDER)
      return {
        removeRecentSearchItem,
        recentSearchItems
      }
    }
  }
</script>

<style lang="scss" scoped>
  .recent-search-item {
    padding: 0;
  }
</style>
