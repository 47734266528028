<template>
  <div class="schedules-search-modal-header">
    <div class="close-button-area">
      <mi-btn
        icon="close"
        icon-type
        fab
        flat
        class="close-button"
        @click="closeModal"
      ></mi-btn>
    </div>
    <mi-btn
      color="accent"
      :loading="isCreateCollectionLoading"
      type="submit"
      :disable="!allowSubmit"
    >
      Save Collection
    </mi-btn>
  </div>
</template>

<script>
  export default {
    name: 'SchedulesSearchModalHeader',
    props: {
      closeModal: {
        type: Function,
        required: false,
        default: () => {}
      },
      isCreateCollectionLoading: {
        type: Boolean,
        required: true,
        default: false
      },
      isEdit: {
        type: Boolean,
        required: false,
        default: false
      },
      allowSubmit: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .schedules-search-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 56px;
    border-bottom: 1px solid $mi-anthracite-50;
    background-color: $mi-white;
    padding-right: 24px;

    .close-button-area {
      height: 100%;
      width: 72px;
      border-right: 2px solid $mi-anthracite-50;
    }

    .close-button {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
      align-items: center;
    }
  }
</style>
