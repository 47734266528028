<template>
  <div class="schedules-search-modal-body">
    <mi-inner-loading class="configuration-info-card__inner-loading" :showing="isElementsLoading">
      <mi-spinner size="70px"></mi-spinner>
      <h6 class="q-mt-lg q-mb-none"> Searching for elements </h6>
    </mi-inner-loading>
    <div class="schedules-search-modal-body__form">
      <search-elements-container
        :product-model-name="scheduleDetails.pmName"
        :on-handle-search="onHandleSearch"
        :on-select-type="onSelectType"
      >
      </search-elements-container>
    </div>
    <div class="schedules-search-modal-body__select-elements">
      <select-elements-container :elements-type="selectedType">
      </select-elements-container>
    </div>
    <div class="schedules-search-modal-body__selected-elements">
      <selected-elements-container
        :planning-period="scheduleDetails.planningPeriod"
      >
      </selected-elements-container>
    </div>
  </div>
</template>

<script>
  import { inject, ref } from 'vue'
  import { useRoute } from 'vue-router'
  import SelectElementsContainer from
    '@/components/schedules/search/select-elements-section/SelectElementsContainer.vue'
  import SelectedElementsContainer from
    '@/components/schedules/search/selected-elements-section/SelectedElementsContainer.vue'
  import SearchElementsContainer from
    '@/components/schedules/search/search-elements-section/SearchElementsContainer.vue'
  import { USE_ELEMENTS_PROVIDER, USE_RECENT_SEARCH_PROVIDER } from '@/constants'
  import { encodeSearchTerm, useSchedulesDetails } from '@/components/schedules/utils'

  export default {
    name: 'SchedulesSearchModalBody',
    components: {
      SearchElementsContainer,
      SelectElementsContainer,
      SelectedElementsContainer
    },
    setup() {
      const route = useRoute()
      const { scheduleDetails } = useSchedulesDetails()
      const selectedType = ref(route.query.resultType || '')
      const { fetchElements, loading: isElementsLoading } = inject(USE_ELEMENTS_PROVIDER)
      const { updateRecentSearchItems } = inject(USE_RECENT_SEARCH_PROVIDER)

      const onSelectType = type => {
        selectedType.value = type
      }

      const onHandleSearch = async ({ searchTerm }) => {
        const encodedSearchTerm = encodeSearchTerm(searchTerm)
        await fetchElements({
          query: encodedSearchTerm,
          pmIdentifier: scheduleDetails.encodedBusinessName
        })
        updateRecentSearchItems(searchTerm)
      }

      return {
        scheduleDetails,
        onHandleSearch,
        selectedType,
        onSelectType,
        isElementsLoading
      }
    }
  }
</script>

<style lang="scss" scoped>
  .schedules-search-modal-body {
    display: flex;
    height: 100%;

    &__form {
      flex: 0 0 20%;
      background-color: $mi-anthracite-50;
    }

    &__select-elements {
      flex: 0 0 40%;
      background-color: rgba(234, 237, 243, .4);
      color: $mi-anthracite-800;
    }

    &__selected-elements {
      flex: 0 0 40%;
    }
  }
</style>
