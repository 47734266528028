<template>
  <mi-expansion-item
    :class="`selected-elements-collection--${ color }`"
    class="selected-elements-collection relative-position overflow-auto q-mb-md"
    expand-icon-class="selected-elements-collection__expand-icon"
    header-class="selected-elements-collection__header text-weight-bold"
    default-opened
  >
    <template #header>
      <mi-list-item-section class="selected-elements-collection__label items-center">
        <slot name="header"></slot>
        <mi-btn
          :ripple="false"
          class="selected-elements-collection__clear-btn q-ml-auto"
          icon="trash"
          icon-size="12px"
          dense
          flat
          icon-type
          fab
          @click.stop="handleClearButtonClick"
        >
          <mi-tooltip anchor="top middle" self="bottom middle"> Clear collection </mi-tooltip>
        </mi-btn>
      </mi-list-item-section>
    </template>
    <div v-if="!!selectedElements.length" class="selected-elements-collection__content q-pt-sm q-pb-md">
      <mi-list separator bordered>
        <search-results-group
          v-for="element in selectedElements"
          :key="element.id"
          :item="element"
          :color="color"
          persistent-title
          removable
          :selected-elements-ids="selectedElementsIds"
          :handle-item-click="onHandleParentElementClick"
          :handle-sub-item-click="onHandleChildElementClick"
        ></search-results-group>
      </mi-list>
    </div>
    <slot name="footer"></slot>
  </mi-expansion-item>
</template>

<script>
  import SearchResultsGroup from '@/components/search/search-results/SearchResultsGroup.vue'
  import { ELEMENT_COLORS, SEARCH_RESULT_TYPES } from '@/constants'

  export default {
    name: 'SelectedElementsCollection',
    components: { SearchResultsGroup },
    props: {
      color: {
        type: String,
        required: false,
        default: 'yellow'
      },
      selectedElements: {
        type: Object,
        required: false,
        default: () => {}
      },
      selectedElementsIds: {
        type: Object,
        required: false,
        default: () => {}
      },
      onHandleChildElementClick: {
        type: Function,
        required: false,
        default: () => {}
      },
      onHandleParentElementClick: {
        type: Function,
        required: false,
        default: () => {}
      },
      onClearCollection: {
        type: Function,
        required: false,
        default: () => {}
      }
    },
    setup(props) {
      const handleClearButtonClick = () => {
        const elementsType = props.color === ELEMENT_COLORS.YELLOW
          ? SEARCH_RESULT_TYPES.OPTIONS : SEARCH_RESULT_TYPES.COMPONENTS
        props.onClearCollection(elementsType)
      }

      return { handleClearButtonClick }
    }
  }

</script>

<style lang="scss" scoped>
  .selected-elements-collection {
    min-height: 56px;
    max-height: 250px;
    font-size: .875rem;
    border-left: 7px solid transparent;
    background-color: $mi-white;
    box-shadow: 0 0 0 1px $mi-silver-300;

    ::v-deep(.selected-elements-collection__header) {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: $mi-white;
    }

    &__clear-btn {
      margin-top: 2px;
      margin-right: -10px;

      ::v-deep(.mi-icon) {
        margin-right: 0;
      }
    }

    &__label {
      flex-direction: row;
      justify-content: flex-start;
    }

    &__content {
      padding-left: .75rem;
      padding-right: .75rem;
    }

    ::v-deep(.selected-elements-collection__expand-icon) .q-expansion-item__toggle-icon {
      font-size: 9px;
    }
  }

  .selected-elements-collection--yellow {
    border-left-color: $mi-yellow-400;
  }

  .selected-elements-collection--green {
    border-left-color: $mi-green-500;
  }
</style>
