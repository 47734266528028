<template>
  <div
    :class="{
      'search-results-table--standout': !!headerColor,
      'disabled no-pointer-events': disable
    }"
    class="search-results-table full-height overflow-hidden-y relative-position"
  >
    <!-- Header -->
    <div
      v-if="productModelChange ? !showNoDataFound || areElementsLoading : true"
      :class="headerColor ? `search-results-table__header--${ headerColor }` : ''"
      class="search-results-table__header flex q-virtual-scroll--skip items-center"
    >
      <!-- Search field -->
      <mi-text-field
        :model-value="modelValue"
        class="search-results-table__input col-grow"
        debounce="300"
        placeholder="Search"
        clearable
        borderless
        dense
        hide-bottom-space
        @clear="$emit('update:model-value', '')"
        @update:model-value="$emit('update:model-value', $event?.trim() || '')"
      >
        <template #prepend>
          <mi-icon name="search" size="18px"></mi-icon>
        </template>
      </mi-text-field>

      <!-- Sort button -->
      <mi-btn
        v-if="false"
        :class="{ 'search-results-table__sort-btn--desc': sortDesc }"
        :ripple="false"
        class="search-results-table__sort-btn q-ml-sm"
        dense
        fab
        flat
      >
        <div class="column">
          <mi-icon name="caret-up" size="8px"></mi-icon>
          <mi-icon name="caret-down" size="8px"></mi-icon>
        </div>
      </mi-btn>
    </div>

    <!-- Scroll area -->
    <mi-scroll-area
      class="search-results-table__scroll-area"
      :class="productModelChange && showNoDataFound && !areElementsLoading? 'pm-change-no-data-background' : ''"
    >
      <mi-virtual-scroll
        ref="miVirtualScroll"
        :items="items"
        virtual-scroll-item-size="46"
        scroll-target=".search-results-table > .scroll"
        separator
      >
        <!-- Items -->
        <template #default="{ index, item }">
          <slot :index="index" :item="item"></slot>
        </template>
      </mi-virtual-scroll>
    </mi-scroll-area>

    <!-- No elements found -->
    <div
      v-if="showNoDataFound && !areElementsLoading"
      class="search-results-table__no-data-found absolute full-width flex flex-center"
      :class="productModelChange? 'pm-change-no-data-background' : ''"
    >
      <div v-if="!productModelChange" class="column items-center text-primary">
        <mi-icon name="warning-circle" size="26px"></mi-icon>
        <h6 class="q-mb-none q-mt-sm"> No elements found </h6>
      </div>
      <div v-else class="pm-change-no-data column items-center text-primary">
        <img src="@/assets/images/vm/product-model/elements-change/select.svg" alt="select" />
        <h6 class="q-mb-none q-mt-sm">No element update for this collection. </h6>
      </div>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { ELEMENT_COLORS } from '@/constants'

  const { mapState } = createNamespacedHelpers('search')

  export default {
    name: 'SearchResultsTable',
    props: {
      disable: {
        type: Boolean,
        required: false,
        default: false
      },
      headerColor: {
        type: String,
        required: false,
        default: '',
        validator: value => Object.values(ELEMENT_COLORS).includes(value) || !value
      },
      items: {
        type: Array,
        required: false,
        default: () => []
      },
      modelValue: {
        type: String,
        required: true
      },
      showNoDataFound: {
        type: Boolean,
        required: false,
        default: false
      },
      productModelChange: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['update:model-value'],
    data: () => ({
      sortDesc: false
    }),
    computed: {
      ...mapState(['areElementsLoading'])
    },
    methods: {
      refreshVirtualScroll() {
        this.$refs.miVirtualScroll?.refresh?.(-1)
      }
    }
  }
</script>

<style lang="scss" scoped>
  $header-height: 56px;
  $table-border-width: 1px;

  .pm-change-no-data-background {
    background: $mi-silver-50;
  }

  .search-results-table {
    border: $table-border-width solid $mi-silver-50;
    min-width: 300px;

    &__scroll-area {
      height: calc(100% - #{$header-height});
    }

    &__no-data-found {
      bottom: 0;
      top: $header-height;

      .pm-change-no-data {
        .q-mt-sm {
          padding-top: 48px;
        }

        .text-select-tab {
          margin-bottom: 45px;
        }
      }
    }

    ::v-deep(.mi-list-item) {
      transition: none;

      &:last-child {
        box-shadow: 0 $table-border-width 0 $mi-silver-200;
      }
    }
  }

  .search-results-table__header {
    height: $header-height;
    padding: .75rem 10px;
    background-color: $mi-silver-50;

    &--yellow {
      background-color: $mi-yellow-400;
    }

    &--green {
      background-color: $mi-green-500;
    }
  }

  .search-results-table__input {
    padding: 0 .5rem;
    background-color: $mi-white;
  }

  .search-results-table__sort-btn.mi-btn {
    padding: 0;
    height: 28px;
    width: 28px;

    ::v-deep(.mi-icon):first-child {
      margin-bottom: -1px;
    }

    &:not(.search-results-table__sort-btn--desc) ::v-deep(.mi-icon):last-child,
    &.search-results-table__sort-btn--desc ::v-deep(.mi-icon):first-child {
      opacity: .4;
    }
  }

  .search-results-table--standout {
    .search-results-table__input {
      background-color: rgba($mi-white, .4);

      &.q-field--focused {
        background-color: rgba($mi-white, .6);
      }
    }
  }
</style>
