<template>
  <div v-if="!currentElementsList.length && !searchStringElements?.length" class="select-elements-container-empty">
    <div class="select-elements-container-empty__content">
      <img
        src="@/assets/images/search-elements-state.svg"
        alt="Elements selection empty"
      />
      <span class="select-elements-container-empty__title">
        Search and then select elements.
      </span>
    </div>
  </div>
  <div v-else class="select-elements-container col">
    <div class="text-right">
      <mi-btn
        outline
        dense
        class="add-all-btn"
        :disable="false"
        @click="addAllElements"
      >
        Add all {{ elementsType === 'componentVariants' ? 'component variants' : elementsType }}
      </mi-btn>
    </div>
    <search-results-table
      ref="previewItemsTable"
      v-model="searchStringElements"
      :header-color="elementColors[elementsType]"
      :items="currentElementsList"
      :show-no-data-found="!!searchStringElements?.length && !currentElementsList.length"
    >
      <template #default="{ item }">
        <search-results-group
          :key="item.id2"
          :item="item"
          :color="elementColors[elementsType]"
          :selected-elements-ids="selectedElementsIds"
          :handle-item-click="onHandleParentElementClick"
          :handle-sub-item-click="onHandleChildElementClick"
          :activate-item-check-box-only-when-all-selected="true"
          :is-element-fully-selected="isElementFullySelected"
          :selected-elements-length="selectedElementsLength"
        ></search-results-group>
      </template>
    </search-results-table>
  </div>
</template>

<script>

  import { computed, inject, ref, watch } from 'vue'
  import SearchResultsGroup from '@/components/search/search-results/SearchResultsGroup.vue'
  import { SCHEDULE_ELEMENT_COLORS, SEARCH_RESULT_TYPES, USE_ELEMENTS_PROVIDER } from '@/constants'
  import SearchResultsTable from '@/components/search/search-results/SearchResultsTable.vue'
  import cloneDeep from 'lodash.clonedeep'
  import { filterElementsListByString, getElementIdsReference, getElementTypes } from '@/utils/filterListByString'

  export default {
    name: 'SelectElementsContainer',
    components: { SearchResultsTable, SearchResultsGroup },
    props: {
      elementsType: {
        type: String,
        required: true
      }
    },
    setup(props) {
      const searchStringElements = ref('')
      const elementColors = ref(SCHEDULE_ELEMENT_COLORS)
      const {
        elements,
        selectedElementsIds,
        selectedElements,
        onHandleChildElementClick,
        onHandleParentElementClick
      } = inject(USE_ELEMENTS_PROVIDER)

      const filterTypeSubElements = (elementsList, elementType) => {
        const { elementId, elementId2, elementName } = getElementIdsReference(elementType)

        const subElementsMap = {}
        const subElementsList = []

        elementsList.forEach(value => {
          const key = value[elementId2]
          if (subElementsMap[key]) {
            subElementsMap[key][elementType].push(value)
          }
          else {
            const newSubElement = {
              id: value[elementId],
              id2: key,
              name: value[elementName],
              [elementType]: [value]
            }

            if (elementType === SEARCH_RESULT_TYPES.COMPONENT_VARIANTS) {
              newSubElement.oid = value.oid
            }

            subElementsMap[key] = newSubElement
            subElementsList.push(newSubElement)
          }
        })

        return subElementsList
      }

      const allElementsList = computed(() => {
        const allElements = { options: [], components: [], componentVariants: [], choices: [] }

        allElements.options = elements.options.elements
        allElements.components = elements.components.elements

        allElements.componentVariants = filterTypeSubElements(
          elements.componentVariants.elements,
          SEARCH_RESULT_TYPES.COMPONENT_VARIANTS
        )

        allElements.choices = filterTypeSubElements(
          elements.choices.elements,
          SEARCH_RESULT_TYPES.CHOICES
        )

        return allElements
      })

      const currentElementsList = computed(() => {
        const searchString = searchStringElements.value

        const elementsList = allElementsList.value[props.elementsType]
          ? cloneDeep(allElementsList.value[props.elementsType]) : []

        return filterElementsListByString({ elementsList, elementType: props.elementsType, searchString })
      })

      const getSelectedElement = (item, elementTypes) => {
        const { item: parentType, subItem: childType } = elementTypes
        const { elementId } = getElementIdsReference(childType)

        const element = elements[parentType].elements.find(el => el.id === item.id)
        const subElement = elements[childType].elements.find(el => el[elementId] === item.id)
        const subElementSelected = subElement && currentElementsList.value.find(el => el.id === subElement[elementId])

        return { element, subElement: subElementSelected }
      }

      const isElementFullySelected = (item, elementTypes, currentSelectedElementsIds) => {
        const selectedChildren = currentSelectedElementsIds.get(item.id)
        if (!selectedChildren) return false

        const { element, subElement } = getSelectedElement(item, elementTypes)

        const isFullySelected = (el, children) => el && children.length === el[elementTypes.subItem].length

        const fullySelected = isFullySelected(element, selectedChildren)
          || isFullySelected(subElement, selectedChildren) || false

        return fullySelected
      }

      const selectedElementsLength = (item, elementTypes, currentSelectedElementsIds) => {
        const { element, subElement } = getSelectedElement(item, elementTypes)

        const childrenLength = el => el && el[elementTypes.subItem].length

        const totalChildren = childrenLength(element) || childrenLength(subElement) || 0
        const totalChildrenSelected = currentSelectedElementsIds.get(item.id)?.length || 0

        return `${ totalChildrenSelected }/${ totalChildren }`
      }

      const addAllElements = () => {
        const elementsToBeAdd = allElementsList.value[props.elementsType]
        const subElementsType = getElementTypes(props.elementsType).subtype

        elementsToBeAdd.forEach(element => {
          const subElementsIds = element[subElementsType]?.map(subElement => subElement.id) || []
          const elementsType = getElementTypes(props.elementsType).type
          selectedElementsIds[elementsType]?.set(element.id, subElementsIds)

          const elementIndex = selectedElements[elementsType].findIndex(el => el.id === element.id)

          if (elementIndex < 0) {
            selectedElements[elementsType]?.push(cloneDeep(element))
          }
          else {
            selectedElements[elementsType][elementIndex] = element
          }
        })
      }

      watch(() => [props.elementsType, allElementsList.value], () => {
        searchStringElements.value = ''
      })

      return {
        currentElementsList,
        elementColors,
        searchStringElements,
        allElementsList,
        filterTypeSubElements,
        isElementFullySelected,
        selectedElementsLength,
        selectedElementsIds,
        onHandleChildElementClick,
        onHandleParentElementClick,
        elements,
        addAllElements,
        selectedElements
      }
    }
  }
</script>

<style lang="scss" scoped>
  .select-elements-container-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__title {
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      color: $mi-anthracite-800;
      line-height: 15.6px;
    }

    &__scroll-area {
      height: 100%;
    }
  }

  .select-elements-container {
    margin: 16px 24px 0;
    height: 90%;

    .add-all-btn {
      margin-bottom: 16px;
    }
  }
</style>
